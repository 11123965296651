import React from 'react'
import './Servicios.scss'
import './example.scss'
import { motion } from "framer-motion";
import ServiciosBasicos from '../Tab/ServiciosBasicos';
import ServiciosEspecializados from '../Tab/ServiciosEspecializados';
// import { Serviciosbasicos } from '../Servisiosbasicos/Serviciosbasicos';

export const Servicios = () => {
    // const [selectedTab, setSelectedTab] = useState(tabs[0]);

    return (
        <div id='servicios' className="Servicios-container">
            <div className="Servicios-selector">


                <div>
                    {/* <h1 className='titulos'> Servicios que ofrecemos</h1> */}
                    <motion.h1 initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.8 }} className="Servicios-text-selector-uno"><span class="blue-text">Nuestro</span> Servicios
                    </motion.h1>

                    <motion.h3 initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.8 }} className="Servicios-text-selector-dos">Mecànica Bàsica
                    </motion.h3>

                    <motion.div initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.8 }}>
                        <ServiciosBasicos />
                    </motion.div>

                    <motion.h3 initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.8 }} className="Servicios-text-selector-dos">Mecànica Especializada
                    </motion.h3>

                    <motion.div initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.8 }}>
                        <ServiciosEspecializados />
                    </motion.div>

                </div>
            </div>
        </div>
    )
}
