import React from 'react';
import background1 from "../Assets/features.jpg";
import check from "../Assets/check.png";
import aire from "../Assets/aire.jpg";
import amortiguadores from "../Assets/amortiguadores.jpg";
import electricidad from "../Assets/electricidad.jpg";
import embrague from "../Assets/embrague.jpg";
import bienes from "../Assets/bienes.jpeg";

import ExampleItem from './ExampleItem';

const code = `const Component = (
  <Tabs>
    <TabList>
      <Tab>Servicio de SCANNER</Tab>
      <Tab>Aire acondicionado</Tab>
      <Tab>Amortiguadores</Tab>
      <Tab>Eletricidad y electronica</Tab>
      <Tab>Revision General</Tab>
      <Tab>Kit de embrague</Tab>
    </TabList>

    <TabPanel>
    <div className="blog-card alt">
    <div className="meta">
        <div className="photo" style={{ backgroundImage: "url(${check})" }}></div>
        <ul className="details">
            <li className="author">MECANICFORD</li>
            <li className="num">3002843563</li>
        </ul>
    </div>
    <div className="description">
        <h1>Servicio de:</h1>
        <h2>Servicio de SCANNER</h2>
        <p>Sirve para detectar un problema particular del carro; debido a que este funciona con diferentes sistemas que trabajan entre sí, es importante realizar el diagnóstico para solucionar la falla sin hacer cambios de repuestos innecesarios.</p>
    </div>
    </div>
    </TabPanel>

    <TabPanel>
    <div className="blog-card alt">
    <div className="meta">
        <div className="photo" style={{ backgroundImage: "url(${aire})" }}></div>
        <ul className="details">
            <li className="author">MECANICFORD</li>
            <li className="num">3002843563</li>
        </ul>
    </div>
    <div className="description">
        <h1>Servicio de:</h1>
        <h2>Aire acondicionado</h2>
        <p>Es importante hacer una limpieza anual del circuito y realizar un tratamiento antibacteriano y cada dos años cambiar los filtros y realizar la recarga de gas. Aqui en MECANICFORD te realizamos el servicio de aire acondicionado</p>
    </div>
    </div>
    </TabPanel>

    <TabPanel>
    <div className="blog-card alt">
    <div className="meta">
        <div className="photo" style={{ backgroundImage: "url(${amortiguadores})" }}></div>
        <ul className="details">
            <li className="author">MECANICFORD</li>
            <li className="num">3002843563</li>
        </ul>
    </div>
    <div className="description">
        <h1>Servicio de:</h1>
        <h2>Amortiguadores</h2>
        <p>Unos amortiguadores desgastados puede tener serias implicaciones en la estabilidad de tu vehículo y, por consiguiente, en tu seguridad. No te olvides de revisar su estado cada 10.000 Km para cambiarlos si es necesario.</p>
    </div>
    </div>
    </TabPanel>

    <TabPanel>
    <div className="blog-card alt">
    <div className="meta">
        <div className="photo" style={{ backgroundImage: "url(${electricidad})" }}></div>
        <ul className="details">
            <li className="author">MECANICFORD</li>
            <li className="num">3002843563</li>
        </ul>
    </div>
    <div className="description">
        <h1>Servicio de:</h1>
        <h2>Eletricidad y electronica</h2>
        <p>Adicional podrás hacer cualquier trabajo eléctrico o electrónico: Desde el cambio de bombillos y la batería, hasta el cambio de sensores complejos del sistema Fuel Injection con personal especializados.</p>
    </div>
    </div>
    </TabPanel>

    <TabPanel>
    <div className="blog-card alt">
    <div className="meta">
        <div className="photo" style={{ backgroundImage: "url(${bienes})" }}></div>
        <ul className="details">
            <li className="author">MECANICFORD</li>
            <li className="num">3002843563</li>
        </ul>
    </div>
    <div className="description">
        <h1>Servicio de:</h1>
        <h2>Revision General</h2>
        <p>identificando las fallas actuales y previendo las futuras con un mantenimiento vehicular para un uso seguro del carro. Te recomendamos hacerla cada 10.000km, especialmente en el caso de los vehículos usados</p>
    </div>
    </div>
    </TabPanel>

    <TabPanel>
    <div className="blog-card alt">
    <div className="meta">
        <div className="photo" style={{ backgroundImage: "url(${embrague})" }}></div>
        <ul className="details">
            <li className="author">MECANICFORD</li>
            <li className="num">3002843563</li>
        </ul>
    </div>
    <div className="description">
        <h1>Servicio de:</h1>
        <h2>Kit de embrague</h2>
        <p>Con el uso y el paso del tiempo, estas piezas empiezan a desgastarse y cuando comienza a sufrir fallos notaremos que se nos dificulta cambiar de marcha, o el motor tendrá problemas al arrancar.</p>
    </div>
    </div>
    </TabPanel>

    </Tabs>
);

render(Component);`;

// const hint =
//   'This is a simple usecase where the Luigi tab is disable by default. You can also use [tab] to focus the tabs and use arrow left/right or up/down to switch tabs.';

export default () => <ExampleItem code={code} label="Servicios Especializados" />;
