import './SecondaryNavBar.scss'
// import ShoppingCart from '../Assets/Icons/shopping-cart.png'
// import {useCartOpen} from '../../Context/ContextProducts';


const SecondaryNavBar = () => {


    const handleClick = () => {
        window.open('https://api.whatsapp.com/send?phone=573002843563&text=buen%20día,%20quisiera%20más%20información', '_blank')
    }

    return (
        <>
            <div className="secondary-navbar">
                <div className='secondary-navbar-selector'>
                    <div className="secondary-navbar-box-1">
                        <p>SERVICIOS MECANICFORD</p>
                    </div>
                    <div className="secondary-navbar-box-2">

                        <button className='now-button' onClick={handleClick}>COTIZAR</button>
                    </div>

                </div>
            </div>

        </>
    )
}

export default SecondaryNavBar