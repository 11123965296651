import './Footer.scss'
import metaLogo from '../Assets/logo-mecanicford.png'
import segura from '../Assets/segura.png'
// import logoPjm from '../Assets/logo-pjm.png'
import { FaFacebookSquare } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";

const Footer = () => {
    return (
        <>
            <div className='container-footer'>
                <div>
                    <img className='imgLogo' src={metaLogo} alt="Logo" />
                </div>
                <div className='separador'>
                    <ul>
                        <li><a href='#home'>HOME</a></li>
                        <li><a href='#servicios'>SERVICIOS</a></li>
                        <li><a href='#nuestrotrabajo'>NUESTRO TRABAJO</a></li>
                        <li><a href='#contactanos'>CONTACTANOS</a></li>
                    </ul>
                </div>
                <div className='separador'>
                    <p>SERVICIO 100% SEGURO</p>
                    <img className='imgSegura' src={segura} alt="compra segura" />
                </div>
                <div className='separador-siguenos'>
                    <div className='texto'>SIGUENOS EN</div>
                    <div className='textoDos'>
                        <a href='https://www.instagram.com/mecanicford' target="_blank" rel='noreferrer'><FaFacebookSquare className='icon' /></a>
                        <a href='https://www.facebook.com/profile.php?id=100076256670729' target="_blank" rel='noreferrer'><FaInstagram className="icon" /></a>
                    </div>
                </div>
            </div>

            <div className='sub-container'>
                <div className='copy'>
                    <p><span>MecanicFord</span> Copyrigth 2022 - Todos los derechos reservados</p>
                    {/* <img src={logoPjm} alt="PJM" /> */}
                </div>
            </div>

        </>
    )
}

export default Footer