import React from "react";
import "./nuestrotrabajo.scss";
import { motion } from "framer-motion";
import { InstagramEmbed } from "react-social-media-embed";

export const Nuestrotrabajo = () => {
  return (
    <div id="nuestrotrabajo" className="nuestrotrabajo-container">
      <div className="nuestrotrabajo-selector">
        <div className="nuestrotrabajo-text-selector">
          {" "}
          <motion.h1
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
          >
           <span class="blue-text">Nuestro</span> Trabajo
          </motion.h1>
          <motion.p
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
          >
            Descubre más sobre nuestros servicios, también nos puedes visitar en
            Instagram y Facebook.
          </motion.p>
        </div>
        <motion.div
        initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
         className="nuestrotrabajo-card-container">
          <div className="nuestrotrabajo-secondary">
            <div style={{ display: "flex", justifyContent: "center" }}>
              <InstagramEmbed
                url="https://www.instagram.com/reel/Ch4fEblA1FX/?utm_source=ig_web_copy_link"
                width={328}
              />
            </div>
            <div style={{ justifyContent: "center" }}>
              <InstagramEmbed
                url="https://www.instagram.com/reel/CiTPBhKABF0/?hl=es-la"
                width={328}
              />
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <InstagramEmbed
                url="https://www.instagram.com/p/Ch5RQWigzbW/"
                width={328}
              />
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <InstagramEmbed
                url="https://www.instagram.com/p/CjbZ_ZSAaKn/"
                width={328}
              />
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};
