import './Header.scss'
import scroll from '../Assets/Icons/scroll-down-icon.png'
import { useState } from "react";
import { motion } from "framer-motion"

import headerImg from '../Assets/header.jpg'
import headerVideo from '../Assets/Produce.mp4'

const Header = () => {


    const [color, setColor] = useState(false);

    const changeColor = () => {
        window.scrollY >= 150 ? setColor(true) : setColor(false)
    }

    window.addEventListener('scroll', changeColor)

    return (
        <header id='home' className='header'>
            <div className=" header-selector">
                <div className="header-box">
                    <motion.p initial={{ opacity: 0, y: -100 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.8 }} className='header-descriptionUno'>TOP SERVICIOS
                    </motion.p>
                    <motion.h1 initial={{ opacity: 0, y: -100 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.8 }}className='header-descriptionUno'>MECANICFORD
                    </motion.h1>
                    {/* <motion.p initial={{opacity: 0, y: 100}}
                              whileInView={{opacity: 1, y: 0}}
                              viewport={{once: true}}
                              transition={{duration: 0.8}} className='header-discount'>25% OFF 25% OFF 25% OFF 25% OFF
                        25% OFF 25% OFF
                    </motion.p> */}
                    <motion.p initial={{ opacity: 0, y: 100 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.8 }} className='header-descriptionDos'>En MecanicFord, somos reconocidos por servicios de reparación y mantenimiento automotriz Ford, transparentes y confiables. Cada uno de nuestros servicios especializados de mécanica tanto mantenimiento como reparación prestados en la ciudad de Medellín, son realizados por mecánicos especializados en la marca Ford, dónde la experiencia de años de servicios en mantenimiento a estos modelos nos permiten brindar un servicio especializado preciso y confiable.
                    </motion.p>

                </div>
                <motion.a initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    viewport={{ once: true }}
                    transition={{ duration: 5 }} href="/#mecanicford">
                    <img className='scroll-icon' src={scroll} alt="#mecanicford" />
                </motion.a>

            </div>
            <video className={color ? 'oculus-video-opacity' : 'oculus-video'} autoPlay loop muted preload={'auto'}
                poster={headerImg}>
                <source src={headerVideo} type='video/mp4' />
            </video>
        </header>
    )
}

export default Header