import './Home.scss'
import Header from "../Header/Header";
import NavBar from "../NavBar/NavBar";
// import Footer from "../Footer/Footer";
import Features from "../Mecanicford/Mecanicford";
import SecondaryNavBar from "../SecondaryNavBar/SecondaryNavBar";
import { Servicios } from '../Servicios/Servicios';
import { Nuestrotrabajo } from '../Nuestrotrabajo/Nuestrotrabajo';
import Contacto from '../Contacto/Contacto';


const Home = ({ loading }) => {
    return (
        <div className={loading ? 'home-selector-none' : 'home-selector-block '}>
            <NavBar />
            <Header />
            <SecondaryNavBar />
            <Features />
            <Servicios/>
            <Nuestrotrabajo/>
            <Contacto/>
            {/* <Footer /> */}
        </div>
    )
}

export default Home