import AnimatedCursor from "react-animated-cursor"
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Home from "./Components/Home/Home";
import './App.scss';
// import NavBar from "./Components/NavBar/NavBar";
// import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
// import { Servicios } from "./Components/Servicios/Servicios";

function App() {

    // const {loading} = useContext(Products);


    return (
        <>

            <AnimatedCursor innerSize={15}
                outerSize={35}
                color='0, 0, 0'
                outerAlpha={0.2}
                innerScale={0.7}
                outerScale={2}
                clickables={[
                    'a',
                    'span',
                    'input[type="text"]',
                    'input[type="email"]',
                    'input[type="number"]',
                    'input[type="submit"]',
                    'input[type="image"]',
                    'input[type="video"]',
                    'label[for]',
                    'select',
                    'textarea',
                    'button',
                    '.link',
                    'video',
                    'VideoCard',
                    'Tab'
                ]} />
            {
                // loading && (<Loeader/>)
                // (<Loeader/>)
            }

            {/* <Home loading={loading} /> */}
            <BrowserRouter>
                <Routes>
                    <Route path='/' element={<Home />} />
                    <Route path='/#servicios' element={<Home />} />
                    {/* <Route path='/game/:id' element={<VideogameDetail />} />
          <Route path='/game/add' element={<FormVideogame />} />
          <Route path='/nofound' element={<NoFound />} />
          <Route path='*' element={<Navigate replace to='/noFound' />} /> */}
                </Routes>
                <Footer />
            </BrowserRouter>

        </>
    );
}

export default App;