import './Mecanicford.scss'
import { motion } from "framer-motion"
import background from "../Assets/features.jpg";

const Features = () => {

    const handleClick = () => {
        window.open('https://api.whatsapp.com/send?phone=573002843563&text=buen%20día,%20quisiera%20más%20información', '_blank')
    }

    return (


        <div id='mecanicford' className="Features-container">

            <div className="Features-selector">
                <motion.div initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.8 }} className="Features-information" style={{ backgroundImage: `url(${background})` }}>
                    <motion.div initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                        transition={{ duration: 1 }} className='Features-information-text'>
                        <h1>¿Por qué traer tu Ford a <br /> <span>MecanicFord</span> <br /> Medellín?</h1>
                        <p>Técnicos especializados y con alta experiencia en marcas Ford. Tecnología de punta. Calidad a precio Justo. Respuestos Basados en las recomendaciones de Ford en sus manuales de taller.</p>
                        <div className="Features-information-buttons">
                            <button onClick={handleClick}>AGENDA AHORA</button>

                        </div>
                    </motion.div>
                </motion.div>
            </div>
        </div>
    )
}

export default Features