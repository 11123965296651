import React from 'react'
import './Contacto.scss'
import { motion } from "framer-motion"

const createMarkup = () => {
    return { __html: '<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15864.45110970894!2d-75.5871955!3d6.2488671!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x1edcea634831db22!2sMecanicFord!5e0!3m2!1ses-419!2sco!4v1664215151682!5m2!1ses-419!2sco" width="400" height="300" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>' };
}

const Contacto = () => {

    const handleClick = () => {
        window.open('https://api.whatsapp.com/send?phone=573002843563&text=buen%20día,%20quisiera%20más%20información', '_blank')
    }

    return (
        <div className="ideas">
            <div id='contactanos' className="Contactos-container">
                <div className="Contactos-selector">
                    <div>
                        <div className="Contactos-text-selector">
                            <motion.h1 initial={{ opacity: 0, y: 50 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                viewport={{ once: true }}
                                transition={{ duration: 0.8 }}>Contactanos
                            </motion.h1>
                            <div className="titlepage">
                            <motion.h2 initial={{ opacity: 0, y: 50 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                viewport={{ once: true }}
                                transition={{ duration: 0.8 }}> Ubicacion & <span onClick={handleClick}>Ir a WhatsApp</span>
                            </motion.h2>
                            </div>
                            <motion.div initial={{ opacity: 0, y: 50 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                viewport={{ once: true }}
                                transition={{ duration: 0.8 }} className="border_trbl">
                                <div dangerouslySetInnerHTML={createMarkup()}></div>
                            </motion.div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contacto