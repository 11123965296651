import React from 'react';
import domicilio from "../Assets/domicilio.png";
import cambioAceite from "../Assets/cambio_de_aceite.jpg";
import cambiarFrenos from "../Assets/cambiar-frenos.png";
import sincronizacion from "../Assets/sincronizacion.jpg";
import viaje from "../Assets/viaje.jpg";
import ExampleItem from './ExampleItem';

const code = `const Component = (
  <Tabs>
    <TabList>
      <Tab>A Domicilio</Tab>
      <Tab>Cambio de aceite</Tab>
      <Tab>Cambio de frenos</Tab>
      <Tab>Sincronizaccion</Tab>
      <Tab>Revision de viajes</Tab>
    </TabList>

    <TabPanel>
    <div className="blog-card">

    <div className="meta">
        <div className="photo" style={{ backgroundImage: "url(${domicilio})" }}></div>
        <ul className="details">
            <li className="author">MECANICFORD</li>
            <li className="num">3002843563</li>
        </ul>
    </div>
    <div className="description">
        <h1>Servicio a:</h1>
        <h2>Domicilio</h2>
        <p>Rápido, inmediato y económico llámenos, para solucionar su problema. Desvare, reparación, revisión general, diagnostico, repuestos, escáner, todo lo que necesite. Llámenos y conoce nuestros precios en mantenimiento preventivo y correctivo.</p>
    </div>
</div>
    </TabPanel>
    <TabPanel>
    <div className="blog-card">
    {/* <div className="blog-card"> */}

    <div className="meta">
        <div className="photo" style={{ backgroundImage: "url(${cambioAceite})" }}></div>
        <ul className="details">
            <li className="author">MECANICFORD</li>
            <li className="num">3002843563</li>
        </ul>

    </div>
    <div className="description">
        <h1>Servicio de:</h1>
        <h2>CAMBIO DE ACEITE</h2>
        <p>El aceite reduce la fricción y controla la temperatura del motor. Cambia seguido el aceite para asegurar el rendimiento y la vida de tu motor. Dependiendo el tipo de aceite los periodos de cambio
         pueden oscilar entre 5.000 a 10.000 Km.</p>
    </div>
</div>
    </TabPanel>
    <TabPanel>
    <div className="blog-card">
    {/* <div className="blog-card"> */}

    <div className="meta">
        <div className="photo" style={{ backgroundImage: "url(${cambiarFrenos})" }}></div>
        <ul className="details">
            <li className="author">MECANICFORD</li>
            <li className="num">3002843563</li>
        </ul>

    </div>
    <div className="description">
        <h1>Servicio de:</h1>
        <h2>Cambio de frenos</h2>
        <p>Son las partes donde su correcto funcionamiento es muy importante, realizamos su mantenimiento o cambio de frenos, para evitar accidentes y reparaciones innecesarias que pueden costar bastante.</p>
    </div>
</div>
    </TabPanel>
    <TabPanel>
    <div className="blog-card">
    {/* <div className="blog-card"> */}

    <div className="meta">
        <div className="photo" style={{ backgroundImage: "url(${sincronizacion})" }}></div>
        <ul className="details">
            <li className="author">MECANICFORD</li>
            <li className="num">3002843563</li>
        </ul>

    </div>
    <div className="description">
        <h1>Servicio de:</h1>
        <h2>sincronizacion</h2>
        <p>La Sincronización permite revitalizar el motor conservándolo y asegurando su máximo rendimiento. En esta se cambian piezas importantes como Filtros y Bujías, y adicional se limpian los inyectores del motor y otros componentes de gran importancia.</p>
    </div>
</div>
    </TabPanel>
    <TabPanel>
    <div className="blog-card">
    {/* <div className="blog-card"> */}

    <div className="meta">
        <div className="photo" style={{ backgroundImage: "url(${viaje})" }}></div>
        <ul className="details">
            <li className="author">MECANICFORD</li>
            <li className="num">3002843563</li>
        </ul>

    </div>
    <div className="description">
        <h1>Servicio de:</h1>
        <h2>revision de viaje</h2>
        <p>Prepárate y viaja tranquilo con la revisión que realizamos en MECANICFORD de su vehículo, Revisión de vehículos y Automóviles para tus viajes, asegúrate que tu auto este bien nosotros te ayudamos, diagnostico de vehículos garantizado.</p>
    </div>
</div>
    </TabPanel>
</Tabs>
);

render(Component);`;

// const hint =
//   'This is a simple usecase where the Luigi tab is disable by default. You can also use [tab] to focus the tabs and use arrow left/right or up/down to switch tabs.';

export default () => <ExampleItem code={code} label="Servicios Basicos" />;
